import { createContext, useContext, useState, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
const HeaderContext = createContext()
import { get, set } from 'es-cookie'
import Header from '@/components/Layout/Header';

export function useHeaderContext() {
  return useContext(HeaderContext)
}

export function HeaderProvider({ children, content, pageHandle }) {
  const router = useRouter();

  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false)
  const [showAnnoucementBar, setShowAnnoucementBar] = useState(false)
  const [hide, setHide] = useState(false)
  const [closedDoors, setClosedDoors] = useState(false)
  const headerRef = useRef()
  const oldScrollYPosition = useRef(0)

  useEffect(() => {
    if (['/cart'].includes(router.asPath)) {
      setClosedDoors(true)
    } 
    if (router.asPath === "/" ) {
      setClosedDoors(false)
    } 
  }, [router.asPath])


  const removeAnnoucementBar = () => {
    setShowAnnoucementBar(!showAnnoucementBar)
    set('hidePrimaryAnnoucement', 'true', { expires: 1, path: '/' })
  }

  const stickyNavbar = () => {
    if (window !== undefined) {
      if (window.scrollY > oldScrollYPosition.current + 250) {
        setHide(true)
        oldScrollYPosition.current = window.scrollY
      } else if (window.scrollY < oldScrollYPosition.current) {
        setHide(false)
        oldScrollYPosition.current = window.scrollY
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', stickyNavbar)
    return () => {
      window.removeEventListener('scroll', stickyNavbar)
    }
  }, [])

  useEffect(() => {
    if (hide) document.querySelector('html').classList.add('nav-is-hidden')
    if (!hide) document.querySelector('html').classList.remove('nav-is-hidden')
  }, [hide])

  useEffect(() => {
    const hidePrimaryAnnoucement = get('hidePrimaryAnnoucement')
    setShowAnnoucementBar(hidePrimaryAnnoucement == 'true' ? false : true)
  }, []);

  useEffect(() => {
    const onRountChangeComplete = () => {
      setMobileMenuIsOpen(false)
    };
    router.events.on('routeChangeComplete', onRountChangeComplete);
  }, [])

  if (!content) {
    return ''
  }

  return (
    <HeaderContext.Provider value={{mobileMenuIsOpen, setMobileMenuIsOpen, hide, setHide, closedDoors, setClosedDoors, showAnnoucementBar, setShowAnnoucementBar, removeAnnoucementBar, headerRef}}>
      <Header ref={headerRef} content={content} pageHandle={pageHandle} />
      {children}
    </HeaderContext.Provider> 
  )
}
